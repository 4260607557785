<template>
    <div>
        <List v-if="activeCom == 'List'" @switchCom="switchCom"/>
        <AddAndEdit
            v-else-if="activeCom == 'AddAndEdit'"
            :schemeNameList="schemeNameList"
            :editData="editData"
            :keyArrTeacher="keyArrTeacher"
            :keyArrStu="keyArrStu"
            @switchCom="switchCom"/>
        <Detail v-else-if="activeCom == 'Detail'" :detailId="detailId" @switchCom="switchCom"/>
    </div>
</template>

<script>
import List from './SEduClassroomPatrolProgramme/list.vue';
import AddAndEdit from './SEduClassroomPatrolProgramme/addAndEdit.vue';
import Detail from './SEduClassroomPatrolProgramme/detail.vue';
export default {
    components: {
        List,
        AddAndEdit,
        Detail
    },
    data() {
        return {
            activeCom: 'List',
            detailId: '',
            schemeNameList: [],
            editData: {},
            keyArrTeacher: [],
            keyArrStu: []
        }
    },
    methods: {
        /**
         * @Description: 组件切换
         * @Author: pyl
         * @Date: 2024-08-27 17:21:08
         */        
        switchCom(obj) {
            this.activeCom = obj.com
            if (obj.com == 'Detail') {
                this.detailId = obj.id
            }
            if (obj.com == 'AddAndEdit') {
                this.schemeNameList = obj.schemeNameList
                this.editData = obj.editData
                if (obj.keyArrTeacher) {
                    this.keyArrTeacher = obj.keyArrTeacher
                }
                if (obj.keyArrTeacher) {
                    this.keyArrStu = obj.keyArrStu
                }
            }
        }
    }
}
</script>